import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0001 from '../../../components/molecules/columnDetailInner0001'
import UsefulFunctionLayout011 from '../../../components/molecules/usefulFunctionLayout011'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0001 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '労働基準法と今後の法改正について',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="働き方改革で変わる労働基準法。今後予定している法改正について解説"
        description="労働の最低条件を定めた法律「労働基準法」。労働基準法の基本原則とは？また今後予定されている法改正ってどんなもの？そんな疑問を解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0001/"
        ogType="article"
        ogTitle="働き方改革で変わる労働基準法。今後予定している法改正について解説"
        ogDescription="労働の最低条件を定めた法律「労働基準法」。労働基準法の基本原則とは？また今後予定されている法改正ってどんなもの？そんな疑問を解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0001.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0001 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout011 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事なし */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0001

export const pageQuery = graphql`
  query C0001 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
