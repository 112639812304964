import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0001 = () => (
  <Wrapper>
    <ColumnH1 label="働き方改革で変わる労働基準法。今後予定している法改正について解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img
        src="/images/column/details/c0001.jpg"
        alt="労働基準法と今後の法改正について"
      />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働条件の最低基準を定める法律として<span>労働基準法</span>
        があります。この法律は労働者を1人でも雇入れた際には強制的に課される法律で、最低基準を下回ってしまうと罰則が適用される法律です。
        <br />
        <br />
        労働基準法は昭和22年に施行された法律ですが、当然、当時とは働き方自体が変わっており、複数回の法改正が行われています。今回は労働基準法と今後の法改正にフォーカスをあてて解説していきます。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">労働基準法の基本原則</a>
          </li>
          <li>
            <a href="#toc2">労働基準法+民法の改正</a>
          </li>
          <li>
            <a href="#toc3">賠償予定の禁止</a>
          </li>
          <li>
            <a href="#toc4">身元保証書</a>
          </li>
          <li>
            <a href="#toc5">今後の法改正</a>
          </li>
          <li>
            <a href="#toc6">最後に</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>労働基準法の基本原則</h1>
      </div>
      <p>
        労働基準法は労働者と使用者が対等の立場において決定すべきものとされていますが、実際には
        <span>使用者側の方が強い立場になる</span>
        ことが多く、履行しなければ使用者に罰則を適用し、法令順守の体制を整えています。
        <br />
        <br />
        また、
        <span>
          職場において規定されている就業規則はその職場における最低基準
        </span>
        であり、それを下回る労働条件の締結は無効となります。もちろん、労働基準法を下回る内容の就業規則も無効となります。
      </p>
      <div id="toc2">
        <h1>労働基準法+民法の改正</h1>
      </div>
      <p>
        労働者を雇い入れる際にはどのような労働条件で雇用を約束するのかを明示しなければなりません。例えばどのような場所で、賃金はどの程度で、休みはいつなのかを明確化しなければ健全な家庭生活が営めなくなります。
        <br />
        <br />
        また、労働基準法と親和性が高い民法が2020年4月1日に改正され、実務上の重要な論点があります。
        <br />
        <br />
        まず、労働基準法第16条では<span>「賠償予定の禁止」</span>
        規定があります。これは、使用者が労働者の債務不履行に対して予め「違約金」を定めることや「損害賠償額を予定」する契約をしてはならないと定義しています。
        <br />
        また、採用時に労働契約と併せて取得する「身元保証書」がありますが双方を併せて確認します。
      </p>
      <Introduction001 />
      <div id="toc3">
        <h1>賠償予定の禁止</h1>
      </div>
      <p>
        まず、「違約金」とは労働者が労働契約を遵守しない場合、使用者に損害が発生していなくても予め決めておいた金額を取り立てることができるものです。
        <br />
        次に「損害賠償額の予定」とは債務不履行が生じた際に賠償すべき損害額を現実に生じた損害額に関わらず一定額として定めることを指します。
        <br />
        <br />
        一般の契約では上記の内容を予定することは少なくありませんが、労働契約では支障がでると言えます。
        <br />
        どのような場合に支障がでるかと言うと、例えば社内である機械を故障させた場合は罰金50万円と明示することで労働者は業務時間中、その機械を使用するたびに委縮してしまうことは想像に難くありません。また、賠償額を予定され、その額をちらつかせることで、退職の自由が不当に侵害される恐れもあります。
        <br />
        そのため「労働契約」では損害賠償の予定を禁止しているということです。しかし、現実に生じた損害についてまで賠償を請求することを禁止する趣旨ではなく、
        <span>あくまで金額を予定する定めをすることは禁止</span>という趣旨です。
      </p>
      <div id="toc4">
        <h1>身元保証書</h1>
      </div>
      <p>
        改正民法に合わせて実務上も重要な論点である身元保証書について確認します。
        <br />
        <br />
        身元保証書が必要なケースとして、
        <span>会社が被った損害を労働者に請求する場合</span>
        です。その際に労働者が行方不明になった場合や連帯して賠償せざるを得ないような場合、予め選定された身元保証人に対して請求ができます。しかし、無制限に請求できてしまうと身元保証人に過度な負担が生じ得ることや、そもそも身元保証人になる人が現れないという問題もあることから、
        <span>
          限度額を定めておかなければ当該身元保証書は無効扱いとされるように改正
        </span>
        されました。
        <br />
        限度額については企業規模などを総合的に勘案し決定する部分ですが、言うまでもなくあまりにも高額となると、身元保証人の選定が進まないという問題もあることから、同業他社の動向や社会通念を考慮し決定すべき点です。
      </p>
      <div id="toc5">
        <h1>今後の法改正</h1>
      </div>
      <h3>割増賃金率の引き上げ改正</h3>
      <p>
        現行の労働基準法では中小企業は法定労働時間を超える時間外労働に対して、25％以上の割増賃金を支払うことが義務付けられています。この部分が
        <span>
          2023年4月以降、「1か月60時間以上」の時間外労働に対して50％以上の割増賃金を支払わなければならなくなります。
        </span>
        大企業については既に施行済みですが、いよいよ中小企業に対しても施行されることとなりました。
        <br />
        また、22時～翌朝5時までの時間を深夜帯と呼び、この時間に労働させている場合、深夜割増も付加されるため、
        <span>50％+25％=75％以上の割増</span>が必要となります。
      </p>
      <div id="toc6">
        <h1 className="read">最後に</h1>
      </div>
      <p>
        労働基準法は2019年4月に大幅な改正が行われ、その後の改正は既に大企業に実施済みの内容を中小企業にも広げるという形が多く採用されています。
        <br />
        労働基準法は多くの条文に対して違反した場合の罰則規定が設けられており、世間の注目度も高い法律です。特に時間外労働については社会問題化した過労死とも密接に関わっており、国を挙げて再発防止に向け、取り組んでいます。
        <br />
        今後予定される中小企業の割増賃金率の引き上げの意図としては、
        <span>
          時間外労働そのものの削減を目的（削減できない場合には人件費が増える）
        </span>
        としており、今後も労働者保護を掲げる労働基準法の改正内容は注視しておく必要があります。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0001
